@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mukta", sans-serif;
  font-weight: 400;
}

*::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
  height: 10px;
}

*::-webkit-scrollbar-track {
  background-color: rgb(17 24 39);
}

*::-webkit-scrollbar-thumb {
  background-color: #0c8ce9;
  border-width: 2rem;
  border-radius: 1rem;
}

h1,
h2,
h3 {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  letter-spacing: -0.03rem;
}

#fastaxText {
  font-family: "Inter", sans-serif;
  color: #0c8ce9;
  font-weight: 800;
  font-style: italic;
  letter-spacing: -0.14rem;
}

#loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0c8ce9; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.transitionEase {
  @apply transition duration-300 ease-in-out;
}

.my-hover-effect-1 {
  @apply transitionEase hover:animate-pulse;
}

.my-hover-effect-underline {
  @apply inline-block relative text-white after:content-[''] after:absolute after:w-full after:scale-x-0 after:h-1 after:bottom-0 after:left-0 after:bg-slate-100 after:origin-bottom-right after:transition after:ease-out after:duration-300 hover:after:scale-x-100 hover:after:origin-bottom-left;
}

.my-button-style-1 {
  @apply transitionEase flex items-center md:bg-slate-800 hover:bg-black mt-auto border-0 px-4 py-2 focus:outline-none hover:text-fastaxBlue cursor-pointer rounded;
}

.my-button-style-2 {
  @apply flex justify-center items-center mt-auto border-0 py-2 px-4 focus:outline-none hover:animate-pulse hover:ease-in-out duration-500 rounded;
}

.my-button-style-1-selected {
  @apply bg-fastaxBlue px-4 py-2 flex items-center text-white;
}

.my-popup-style-1 {
  @apply bg-slate-200 bg-opacity-95 w-56 p-3 flex flex-col justify-center items-center text-center text-black shadow-xl rounded gap-3;
}

.my-button-style-cancel {
  @apply bg-red-500 text-white px-3 py-2 hover:bg-red-600 hover:text-white rounded transition duration-300 ease-in-out disabled:bg-slate-500 disabled:hover:text-white;
}

.my-card-style-1 {
  @apply bg-fastaxBlue w-full p-5 rounded drop-shadow-lg;
}

.my-input-style-1 {
  @apply h-9 px-4 flex items-center outline-0;
}

.my-gradient-1 {
  @apply bg-gradient-to-b from-fastaxBlue to-[#56CCF2];
}

.my-gradient-2 {
  @apply bg-gradient-to-b from-amber-400 to-infokeyYellow;
}

/* Sticky Social Icons */

.my-social-sticky-icons {
  @apply fixed bottom-10 left-2 flex flex-col gap-1;
}

.my-social-sticky-icons a {
  transform: translate(-140px, 0px);
  transition: all 0.8s;

  @apply w-full p-2 flex justify-end text-right uppercase rounded-r-xl;
}

.my-social-sticky-icons a:hover {
  color: #fff;
  transform: translate(-10px, 0px);
}

.my-social-sticky-icons a:hover .social-icon {
  transform: rotate(360deg);
}

.my-social-sticky-icons a .social-icon {
  transition: all 0.5s;
  @apply w-9 h-9 ml-7;
}

/* End of Sticky Social Icons CSS */
